<template>
  <div class="dashbard">
    <h1>Activities
      <ui-spinner v-if="show_spinner" active size="small"></ui-spinner>
    </h1>
    <div v-show="!creating">
      <div v-if="activities==null">
        Loading
      </div>
      <div id="no-activities" v-if="activities && activities.length === 0">
        No activities yet
      </div>
      <div id=activity-list>
        <div class="activity" v-for="activity in activities" :key="activity.id">
          <ui-button outlined @click="open_activity(activity.id)">{{activity.name}}</ui-button>
        </div>
      </div>
      <div>
        <ui-button raised @click="create_activity_view">Create Activity</ui-button>
      </div>
    </div>
    <div v-if="creating">
      <ui-form type="|" item-margin-bottom="16" action-align="center">
        <template #default="{ actionClass }">
          <ui-form-field class="required">
            <label>Name:</label>
            <ui-textfield v-model="name"></ui-textfield>
          </ui-form-field>
          
          <ui-form-field :class="actionClass">
            <ui-button raised @click="create_activity">Submit</ui-button>
            <ui-button outlined @click="creating=!creating">Cancel</ui-button>
          </ui-form-field>
        </template>
      </ui-form>
    </div>
  </div>
</template>



<script>
export default {
  data(){
    return {
      creating: false,
      name: '',
      show_spinner: false
    }
  },
  computed: {
    activities(){
      return this.$store.state.activities
    }
  },
  methods: {
    open_activity(activity_id){
      const activity = this.activities.find(a=>a.id===activity_id)
      this.$store.commit('set_activity_id', activity_id)
      this.$router.push({name:`Activity`, params:{id:activity_id }} )
    },
    create_activity_view(){
      this.creating = true
    },
    async create_activity(){
      this.show_spinner = true
      let activity = {
        "name": this.name,
        "members": {
          [this.$store.state.users.profile.id]:{
            name: this.$store.state.users.profile.name || this.$store.state.users.user.email
          }
        }
      };
      let new_activity = await this.$store.dispatch("create_activity", activity)
      this.show_spinner = false
      this.creating = false
      this.$store.commit('set_activity_id', new_activity.id)
      this.$router.push({name:`Activity`, params:{id:new_activity.id }} )
    }
  },
  async created(){
    this.$store.dispatch("get_activities")
  }
}
</script>

<style scoped>
.activity {
  padding: 1em;
}
#no-activities {
  padding: 2em
}
.mdc-form--vertical .mdc-form__item {
  align-items: center;
}
</style>